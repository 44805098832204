.react-tabs__tab {
  font: "SegoeUI";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  margin-top: 24px;
  margin-right: 80px;
  margin-top: 29px;
  margin-bottom: 24px;
  padding-left: 0px;
  padding-right: 0px;
}

.style1 {
  font-family: "SegoeUI";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.45);
}

.tester {
  width: 100%;
  background-color: #000;
}

.border_bottom_line {
  border-bottom: solid 1px #404040;
  padding-bottom: 20px;
}

.margin_table {
  margin-bottom: 5px;
}

.react-tabs__tab-panel--selected {
  display: inline-block;
  padding: 24px 0;
}

.table_border1 {
  border-top: 1px solid #404040;
  padding-top: 1.5rem;
}

.react-tabs__tab--selected {
  background: #000;
  border-color: #000;
  color: white;
}

.react-tabs {
  border-bottom: 1px solid #404040;
  border-top: 1px solid #404040;
  margin: 0 0 10px;
  padding: 0;
  margin-bottom: 24px;
}

.Collapsible__contentOuter {
  border-top: 1px solid #404040;
  margin: 0 0 10px;
  padding: 0;
}

/* .zh_lang {
color:red
} */

.tc_lang {
  color: green;
}

.en_lang {
  color: #ffffff;
}

.blue {
  color: blue;
}
.p_jurisdiction {
  color: #d91d22;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #404040;
  margin: 0;
}

.entity_style {
  font-size: 12px;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
}

.collapsible-trigger {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
}
@media screen and (max-width: 600px) {
  .copyright {
    margin-top: unset !important;
  }
  .table_border1 {
    padding-top: 16px;
  }
  .collapsible-trigger {
    padding-bottom: 16px;
    justify-content: space-between;
  }
  .collapsible-trigger > p {
    font-size: 14px;
  }

  .Collapsible__contentOuter {
    border-top: unset;
    border-bottom: 1px solid #404040;
  }
  .border_bottom_line {
    border-bottom: unset;
  }
}

.collapsible-trigger-img {
  height: 24px;
  width: 24px;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  margin: auto 0;
}

.is-open .collapsible-trigger-img {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
/* .Collapsible__trigger is-open{
    font-size: 12px;
    padding-top:24px;
    position:relative;

} */

.Collapsible__trigger {
  position: relative;
}

.react-tabs__tab::after {
  top: 54px;
}

.react-tabs__tab--selected::after {
  /* display block for selected */
  display: block;
}
.react-tabs__tab--selected::after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.complianceWrapper h5:after {
  content: "";
  max-height: 516px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 9px;
  width: 10px;
  float: right;
  position: absolute;
  right: 67%;
  top: 50%;
  margin-top: -7.28px;
}

.react-tabs__tab--selected {
  position: relative;
}

.react-tabs__tab--selected::after {
  content: "";
  width: 50px;
  height: 1px;
  position: absolute;
  left: 50%;
  bottom: 1;
  margin-left: -25px;
  background-color: #ff0000;
  z-index: 1;
}

.react-tabs__tab {
  position: relative;
  margin-top: 16px;
}

.copyright {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #404040;
  width: 100%;
  background: #000;
  color: white;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.Collapsible__contentInner {
  padding: 0 0;
}

.career {
  display: grid;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  font-family: SegoeUI;
}

.career > a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}
.career > a:hover {
  color: #fff;
}

footer .detail .website {
  margin-top: 16px;
}

.border-bottom-un-set {
  border-bottom: unset;
}

html[lang="en"] .image-row-content .title,
html[lang="en"] .image-row-content .description,
html[lang="en"] .blog-content .title,
html[lang="en"] .blog-content .description,
html[lang="en"] .blog-content .date,
html[lang="en"] .three-image-banner-content .description,
html[lang="en"] .image-with-text-box-container-mobile > div > .red-content > .description,
html[lang="en"] .mobile-nav-content > .mobile-language-row,
html[lang="en"] .language-option,
html[lang="en"] .image-with-text-box-description {
  font-family: SegoeUI;
}
html[lang="tc"].image-row-content .description,
html[lang="tc"] .blog-content .title,
html[lang="tc"] .blog-content .description,
html[lang="tc"] .blog-content .date,
html[lang="tc"] .three-image-banner-content .description,
html[lang="tc"] .image-with-text-box-container-mobile > div > .red-content > .title,
html[lang="tc"] .image-with-text-box-container-mobile > div > .red-content > .description,
html[lang="tc"] .mobile-menu-panel .title,
html[lang="tc"] .mobile-nav-content > div > a,
html[lang="tc"] .mobile-nav-content > div > .language-text,
html[lang="tc"] .mobile-nav-content > .mobile-language-row,
html[lang="tc"] .language-option,
html[lang="tc"] .image-with-text-box-description {
  font-family: YaHei;
}

html[lang="en"] .mobile-nav-content > div > a,
html[lang="en"] .mobile-nav-content > div > .language-text,
html[lang="en"] .mobile-menu-panel .title {
  font-family: Oswald;
}

html[lang="en"] .three-image-banner-content .title,
html[lang="en"] .image-with-text-box-container-mobile > div > .red-content > .title,
html[lang="en"] .image-with-text-box-title {
  font-family: Oswald-Bold;
}

html[lang="en"] .landing-banner-button {
  font-family: SegoeUI-Bold;
}

html[lang="tc"] .three-image-banner-content .title,
html[lang="tc"] .landing-banner-button,
html[lang="tc"] .image-with-text-box-title,
html[lang="tc"] .image-row-content .title {
  font-family: YaHei-Bold;
}

.sticky-header {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 152px;
}

.scrollMargin {
  scroll-margin-top: 152px;
}

.padding-sticky-header {
  height: 152px;
}

.nav-bar {
  background-color: #000;
  width: 100%;
  height: 36px;
  /* fixed height */
}

.nav-bar > div:first-child {
  max-width: 1404px;
  color: white;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}

.language-text {
  margin-left: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.globe {
  height: 16px;
  width: 16px;
  margin-right: 0.75rem;
}

.header-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 116px;
  padding: 24px 0 24px 0;
  margin: 0 auto;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  box-shadow: 0 4px 32px 0 rgba(30, 30, 30, 0.15);
  background-color: #fff;
  align-items: center;
  justify-content: center;
}

.mobile-header-box {
  display: none;
  flex-direction: row;
  width: 100%;
  height: 76px;
  padding: 16px 24px;
  box-shadow: 0 4px 32px 0 rgba(30, 30, 30, 0.15);
  background-color: #fff;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
}

.hamburger {
  width: 24px;
  height: 24px;
}

.haeder-logo {
  width: 255px;
  margin: 0 36px;
}

html[lang="en"] .header-link {
  font-family: Oswald;
}
html[lang="tc"] .header-link {
  font-family: YaHei;
}

.header-link {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  padding: 0 36px;
}

.menu-selected > .header-link {
  color: #e03323 !important;
}

.menu-selected .underline {
  display: block !important;
}

.menu-selected {
  color: #e03323 !important;
}

.header-wrapper > .underline {
  display: none;
  border-bottom: 2px solid #e03323;
  width: 24px;
  margin: 0 auto;
}

.header-link:hover {
  color: #e03323;
}

.landing-container {
  max-width: 1920px;
  margin: 0 auto;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #000;
  min-width: 100px;
  padding: 12px 16px;
  z-index: 1;
  flex-direction: column;
}

.dropdown-content > a {
  padding: 8px 0;
  color: white;
}

.dropdown-content > a:hover {
  color: #e03323;
}

.dropdown:hover .dropdown-content {
  display: flex;
}

.language-text:hover {
  color: #e03323;
}

.image-with-text-box-container {
  max-width: 1404px;
  position: relative;
  margin: 120px auto;
}

.image-with-text-box-container > img {
  max-width: 100%;
  width: 929px;
  height: 525px;
}

.image-with-text-box-container > .content {
  position: absolute;
  top: 15%;
  right: 0;
  background-color: #e03323;
  width: 688px;
  padding-left: 72px;
  padding-right: 100px;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: row;
}

.image-with-text-box-container > .content > img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-top: 11px;
}

.image-with-text-box-description {
  padding-top: 16px;
  font-size: 16px;
  text-align: left;
  color: #fff;
}

.image-with-text-box-title {
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 40px;
  line-height: 1.3;
  text-align: left;
  color: #fff;
}

.landing-banner {
  height: 950px;
  /* height: calc(100vh - 116px - 36px);
  min-height: 950px;
  max-height: 950px; */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  background-image: url(/static/media/Image-header.408f1b9e.png);
}

.landing-title {
  padding-top: 40px;
  flex-direction: column;
  display: flex;
  padding-bottom: 0px;
}

.landing-title-1 {
  text-transform: uppercase;
  font-family: Oswald;
  font-size: 120px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #e03323;
}

.center-title-1 {
  text-transform: uppercase;
  padding-top: 8px;
  font-family: Oswald;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #3e3a39;
}
.center-title-2 {
  text-transform: uppercase;
  font-family: Oswald;
  font-size: 120px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #e03323;
}

.landing-title-cn-1 {
  text-transform: uppercase;
  font-family: YaHei;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #3e3a39;
}
.landing-title-2 {
  text-transform: uppercase;
  padding-top: 8px;
  font-family: Oswald;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #3e3a39;
}

.landing-title-cn-2 {
  padding-top: 8px;
  text-transform: uppercase;
  font-family: YaHei-Bold;
  font-size: 120px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #e03323;
}

html[lang="en"] .landing-title-3 {
  font-family: SegoeUI;
}
html[lang="tc"] .landing-title-3 {
  font-family: YaHei;
}
.landing-title-3 {
  padding-top: 24px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #3e3a39;
}

.landing-banner-button {
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: 232px;
  border-bottom: 1px solid #e03323;
  color: #e03323;
  padding: 16px 36px;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
}
.landing-banner-button:hover {
  color: #fff;
  background-color: #e03323;
}

.foot-image {
  display: flex;
  justify-content: baseline;
  /* max-width: 1920px; */
  width: 100%;
  margin: 0 auto;
}

.foot-image > .desktop,
.mobile {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}

.foot-image > .desktop {
  display: block;
  height: 200px;
}

.foot-image > .mobile {
  min-height: 385px;
  display: none;
}

.center-banner {
  background-image: url(/static/media/Image-Future.c6303b45.png);
  min-height: 1280px;
}

html[lang="en"] .center-banner-description {
  font-family: SegoeUI;
}
html[lang="tc"] .center-banner-description {
  font-family: YaHei;
  max-width: 514px;
}

.center-banner-description {
  max-width: 690px;
  margin: 24px auto;
  font-family: SegoeUI;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #3e3a39;
}

.center-banner-title {
  flex-direction: column;
  display: flex;
  padding-bottom: 0px;
  padding-top: 120px;
}

.center-title {
  text-transform: uppercase;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

html[lang="en"] .center-title > h2 {
  font-family: Oswald;
}
html[lang="tc"] .center-title > h2 {
  font-family: YaHei;
}

.center-title > h2 {
  font-size: 56px;
  line-height: 1.29;
  text-align: center;
  color: #3e3a39;
  padding-bottom: 24px;
  padding-top: 120px;
}

html[lang="en"] .center-title > p {
  font-family: SegoeUI;
}
html[lang="tc"] .center-title > p {
  font-family: YaHei;
}

.center-title > p {
  text-transform: none;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #3e3a39;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-top: 11px;
}

.three-image-row {
  max-width: 1404px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin: 0 auto;
  margin-top: 80px;
  justify-content: center;
}

.image-row-content {
  display: flex;
  flex-direction: column;
}

.image-row-content > img {
  padding-left: 24px;
}

.image-row-content > div {
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  padding-right: 24px;
}

.small-arrow-icon {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  margin-top: 6px;
}

.fade-arrow-icon {
  opacity: 50%;
  width: 20px;
  height: 20px;
  margin-right: 4px;
  margin-top: 6px;
}

.image-row-content .title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
}
.image-row-content .description {
  padding-top: 16px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.image-row-content .first-image-content {
  padding-left: 24px;
}

.grey-bg {
  background-color: #fafafa;
  padding-bottom: 120px;
}

.three-blog-row {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  margin-bottom: 160px;
  justify-content: center;
  position: relative;
}

.three-blog-row > div {
  display: flex;
  overflow-y: auto;
  flex-direction: row;
}

.three-blog-row > .next-button,
.three-blog-row > .previous-button {
  display: block;
  border: 1px solid #e03323;
  padding: 6.5px 8px;
  z-index: 10;
  position: absolute;
  bottom: -22px;
  background: white;
  cursor: pointer;
}

.three-blog-row > .next-button:disabled,
.three-blog-row > .previous-button:disabled {
  border-color: #d4d4d4;
  background: white;
  cursor: not-allowed;
}

.three-blog-row > .next-button:disabled > svg,
.three-blog-row > .previous-button:disabled > svg {
  color: #d4d4d4;
}

.three-blog-row > .next-button > svg,
.three-blog-row > .previous-button > svg {
  height: 30px;
  color: #e03323;
}

.three-blog-row > .next-button {
  left: calc(164px + 249px);
}

.three-blog-row > .next-button > svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.three-blog-row > .previous-button {
  left: calc(100px + 249px);
}

.three-blog-row-margin-bottom > div > a {
  margin-bottom: 80px;
}

/* width */
.three-blog-row > div::-webkit-scrollbar {
  width: 20px;
  height: 5px;
}

/* Track */
.three-blog-row > div::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  background: #e3e3e3;
  margin-left: calc(15vw + 249px);
  margin-right: 25vw;
  margin-top: 60px;
}

/* Handle */
.three-blog-row > div::-webkit-scrollbar-thumb {
  background: #e03323;
}

.three-blog-row > div > div {
  height: 100%;
  padding: 0 12px;
}

.blog-content {
  max-width: 452px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  padding-top: 16px;
  transition: padding 0.5s;
  transition-timing-function: ease-in-out;
}
.blog-content:hover {
  padding-top: 0px;
  padding-bottom: 16px;
}

.blog-content > div {
  height: 100%;
  padding: 32px 32px;
  color: #000;
  background-color: #fafafa;
}

html[lang="en"] .blog-content .title {
  text-transform: capitalize;
  min-height: 96px;
}

.blog-content .title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: #000;
}

.blog-content .description {
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  padding-top: 16px;
}

.blog-content .date {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  justify-self: end;
  color: #9e9e9f;
  padding-top: 24px;
}
.three-image-banner-main-container {
  padding-top: 75px;
}

.three-image-banner-main-container > div {
  display: flex;
  flex-direction: column;
}

.three-image-banner-main-container > div {
  margin: 0 auto;
}

.three-image-banner-main-container > div > div {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  margin: 0 auto;
  max-width: 1646px;
}

.three-image-banner-content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  z-index: 2;
}

.three-image-banner-content > div {
  display: flex;
  flex-direction: row;
  margin: auto auto;
  padding-top: 120px;
}

.three-image-banner-content .title {
  text-transform: uppercase;
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 16px;
}

.three-image-banner-content .description {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 120px;
}

.image-with-text-box-container-mobile {
  display: none;
  padding-top: 80px;
}
.image-with-text-box-container-mobile > div {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  position: relative;
  height: auto;
}

.image-with-text-box-container-mobile > div > img {
  width: 100%;
  object-fit: contain;
}

.image-with-text-box-container-mobile > div > .red-content {
  display: flex;
  position: absolute;
  top: 90%;
  flex-direction: column;
  background-color: #e03323;
  color: #fff;
  padding: 32px 24px;
  margin: 0 8px;
}

.image-with-text-box-container-mobile > div > .red-content > .title {
  text-transform: uppercase;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
}

.image-with-text-box-container-mobile > div > .red-content > .description {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  padding-top: 16px;
}

.three-image-banner-image-content {
  display: flex;
}

.three-image-banner-image-content > img {
  width: 100%;
  object-fit: cover;
  justify-content: baseline;
}

.three-image-banner-image-content-float {
  display: flex;
  position: relative;
  width: 100%;
}

.three-image-banner-image-content-float > img {
  position: absolute;
  bottom: 0;
  width: 100%;
  object-fit: contain;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-6 {
  grid-column: span 6 / span 6;
}
.col-span-7 {
  grid-column: span 7 / span 7;
}
.red-bg {
  background-color: #e03323;
  color: #fff;
}

.center-banner-image {
  display: none;
}

.mobile-menu-panel {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  left: -100vw;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

.mobile-menu-panel-go-in {
  -webkit-animation-name: go-in;
          animation-name: go-in;
  left: 0;
}

@-webkit-keyframes go-in {
  from {
    left: -100vw;
  }
  to {
    left: 0;
  }
}

@keyframes go-in {
  from {
    left: -100vw;
  }
  to {
    left: 0;
  }
}

.mobile-menu-panel-go-out {
  -webkit-animation-name: go-out;
          animation-name: go-out;
  left: -100vw;
}

@-webkit-keyframes go-out {
  from {
    left: 0;
  }
  to {
    left: -100vw;
  }
}

@keyframes go-out {
  from {
    left: 0;
  }
  to {
    left: -100vw;
  }
}

.mobile-menu-panel > div {
  display: flex;
  flex-direction: row;
}

.mobile-menu-panel .title {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
}

.player-wrapper {
  position: relative;
  max-height: min(calc(100vh - 150px), 1280px) !important;
  /* padding-top: 56.25%; */
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  /* top: 0;
  left: 0; */
}

.overlay-prevent-click {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 40px);
  z-index: 1;
}

.play-button-video {
  margin: auto;
  margin: auto;
  height: 200px;
  width: 200px;
  cursor: pointer;
}

.play-button {
  margin-top: auto;
  margin-bottom: 232px;
  height: 200px;
  width: 200px;
  cursor: pointer;
}

.first-blog {
  margin-left: 249px;
}

@media screen and (max-width: 1400px) {
  .first-blog {
    margin-left: 0px;
  }

  .three-blog-row > .next-button {
    left: 264px;
  }

  .three-blog-row > .previous-button {
    left: 200px;
  }

  .three-image-banner-image-content-float > img {
    bottom: 0;
    width: 100%;
    object-fit: contain;
  }
  .three-image-banner-image-content {
    position: relative;
  }
  .three-image-banner-image-content > img {
    bottom: 0;
    position: absolute;
    object-fit: contain;
  }
  .three-blog-row > div::-webkit-scrollbar-track {
    margin-left: 25vw;
  }
}
@media screen and (max-width: 1400px) {
  .three-blog-row > .next-button {
    left: 164px;
  }

  .three-blog-row > .previous-button {
    left: 100px;
  }
}

@media screen and (max-width: 600px) {
  .three-image-banner-image-content > img,
  .three-image-banner-image-content-float > img {
    position: unset;
  }
  .isMobile {
    display: block !important;
  }
  .isDesktop {
    display: none !important;
  }
  .scrollMargin {
    scroll-margin-top: 80px;
  }

  .app {
    overflow-x: hidden;
  }
  .header-box,
  .nav-bar {
    display: none !important;
  }

  .sticky-header,
  .padding-sticky-header {
    height: 76px;
  }
  .mobile-header-box {
    display: flex !important;
  }

  .foot-image > .desktop {
    display: none;
  }

  .foot-image > .mobile {
    display: block;
  }

  .landing-title-1,
  .landing-title-cn-2,
  .center-title-2 {
    font-size: 49px;
  }

  .landing-title-2,
  .landing-title-cn-1,
  .center-title-1 {
    font-size: 28px;
  }
  .landing-title-3 {
    padding: 16px 25px;
    font-size: 16px;
  }

  .landing-banner {
    height: 564px;
    min-height: unset;
    max-height: unset;
    background-size: 280%;
    background-image: url(/static/media/Image-Header.02e66d73.png);
  }
  .landing-banner-button {
    padding: 12px 16px;
    margin-bottom: 122px;
  }

  .play-button {
    margin-bottom: 100px;
  }

  .center-title {
    padding: 0 24px;
  }
  .center-title > h2 {
    font-size: 28px;
    padding-bottom: 18px;
    padding-top: 80px;
  }

  .center-title > p {
    font-size: 16px;
  }

  .image-with-text-box-container {
    display: none;
  }
  .image-with-text-box-container-mobile {
    display: block;
  }

  .image-row-content {
    width: 90vw;
  }

  .blog-content {
    width: 80vw;
  }

  .blog-content {
    padding-bottom: 60px;
  }

  .blog-content .title {
    min-height: unset !important;
    font-size: 20px;
  }

  .blog-content .description {
    font-size: 16px;
  }

  .image-row-content .description {
    padding-bottom: 100px;
  }

  .image-row-content > img {
    padding-left: 8px;
  }

  .image-row-content > div {
    padding-right: 24px;
    padding-left: 8px;
  }

  .image-row-content .first-image-content {
    padding-left: 8px;
  }
  .three-blog-row,
  .three-image-row {
    overflow-y: auto;
    grid-template-columns: repeat(3, minmax(-webkit-min-content, -webkit-max-content));
    grid-template-columns: repeat(3, minmax(min-content, max-content));
    justify-content: start;
    padding: 0 8px;
    margin-top: 40px;
  }
  .three-image-row .fade-arrow-icon {
    display: none;
  }
  .three-blog-row {
    margin-bottom: 80px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .three-blog-row > div {
    padding-left: 12px;
    padding-right: 12px;
  }

  .three-blog-row-margin-bottom > div > a {
    margin-bottom: unset;
  }

  .three-blog-row > .next-button,
  .three-blog-row > .previous-button {
    display: none !important;
  }

  /* Track */
  .three-blog-row > div::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 60px;
  }
  /* width */
  .three-image-row::-webkit-scrollbar {
    width: 20px;
    height: 5px;
    padding-top: 60px;
  }

  /* Track */
  .three-image-row::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    background: #e3e3e3;
    margin: 24px;
    margin-top: 60px;
  }

  /* Handle */
  .three-image-row::-webkit-scrollbar-thumb {
    background: #e03323;
  }

  .center-banner {
    background-image: none;
    height: auto;
    padding-top: 80px;
    position: relative;
  }

  .center-banner > .center-banner-title,
  .center-banner > .center-banner-description {
    padding: 0 24px;
    margin-bottom: 0;
    z-index: 1;
  }
  html[lang="en"] .center-banner > .center-banner-description {
    padding-bottom: 350px;
  }
  html[lang="tc"] .center-banner > .center-banner-description {
    padding-bottom: 340px;
  }

  .center-banner-title {
    padding-top: 80px;
  }

  .center-banner-image {
    height: 483px;
    display: block;
    position: absolute;
    justify-self: baseline;
    justify-items: baseline;
    object-fit: contain;
    z-index: -1;
  }

  html[lang="en"] .center-banner > .center-banner-image {
    top: 30%;
  }

  html[lang="tc"] .center-banner > .center-banner-image {
    top: 20%;
  }

  .three-image-banner-main-container {
    padding-top: 0px;
  }

  .three-image-banner-main-container > div > div {
    display: flex;
    flex-direction: column;
    /* grid-template-columns: repeat(12, minmax(0, 1fr));
    margin: 0 auto;
    max-width: 1646px; */
  }

  .three-image-banner-content > div {
    display: flex;
    flex-direction: row;
    margin: auto auto;
    padding-top: 80px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .three-image-banner-content .title {
    font-size: 24px;
    padding-bottom: 16px;
  }

  .three-image-banner-content .description {
    font-size: 16px;
    padding-bottom: 40px;
  }

  .grey-bg {
    padding-bottom: 80px;
  }
  .three-blog-row > div {
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
            column-gap: 8px;
    padding-bottom: 60px;
  }
  .blog-content {
    padding: 0px;
  }
  .small-arrow-icon {
    width: 16px;
    height: 16px;
  }
  .arrow-icon {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    margin-top: 8px;
  }
}

.mobile-nav-bar {
  position: fixed;
  background: #fff;
  width: 100%;
  height: 80px;
}

.mobile-nav-bar > div {
  float: right;
  margin-right: 2rem;
  margin-top: 80px;
}

.mobile-nav-bar > div {
  position: fixed;
  background: #fff;
  width: 100%;
  height: calc(100vh - 80px);
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  transition: all 0.5s;
  left: -100%;
  padding: 80px 24px;
}

.mobile-nav-bar #menuBtn:checked ~ div {
  left: 0;
}

.mobile-nav-content > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 40px;
  width: 100%;
}

.mobile-nav-content > div > a,
.mobile-nav-content > div > .language-text {
  text-transform: uppercase;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #3e3a39;
}

.mobile-nav-content > div > img {
  height: 16px;
  width: 16px;
  margin-right: 16px;
}

.mobile-nav-content > hr {
  color: #e3e3e3;
  height: 1px;
  width: 100%;
}

.mobile-nav-content > .mobile-language-row {
  padding-top: 80px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 0.88;
  text-align: left;
  color: #3e3a39;
}

.mobile-nav-content > .mobile-language-row img {
  height: 16px;
  width: 16px;
  margin: auto 0;
}
.mobile-nav-content > .mobile-language-row p {
  margin-right: 8px;
  margin-left: 8px;
}

.language-option {
  padding-left: 40px;
  font-size: 16px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

/* caption styling */
.mu-video::cue {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}

html[lang="en"] .mu-video::cue {
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 32px;
}

html[lang="tc"] .mu-video::cue {
  font-family: Alibaba-PuHuiTi;
  font-weight: 500;
  /* font-size: 38px; */
}

@media screen and (max-width: 600px) {
  html[lang="tc"] .mu-video::cue,
  html[lang="en"] .mu-video::cue {
    font-size: 16px;
  }
}

.vjs-text-track-display div {
  font-size: 1.2em;
  color: #fff;
}

html[lang="en"] .vjs-text-track-display div{
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 32px;
}

html[lang="tc"] .vjs-text-track-display div{
  font-family: Alibaba-PuHuiTi;
  font-weight: 500;
  /* font-size: 38px; */
}

@media screen and (max-width: 600px) {
  html[lang="tc"] .vjs-text-track-display div,
  html[lang="en"] .vjs-text-track-display div{
    font-size: 16px;
  }
}
/* Font */
@font-face {
  font-family: "Poppins-Regular";
  src: url(/static/media/Poppins-Regular.731a28a4.ttf);
}

@font-face {
  font-family: "Poppins-Medium";
  src: url(/static/media/Poppins-Medium.a4e11dda.ttf);
}

@font-face {
  font-family: "Georgia";
  src: url(/static/media/Georgia.a9f9eef0.ttf);
}

@font-face {
  font-family: "Oswald-Bold";
  src: url(/static/media/Oswald-Bold.452bfeb5.ttf);
}

@font-face {
  font-family: "Oswald";
  src: url(/static/media/Oswald-Regular.a7ccbd3c.ttf);
}

@font-face {
  font-family: "SegoeUi";
  src: url(/static/media/Segoe-UI.0e7e9a9b.ttf);
}
@font-face {
  font-family: "SegoeUi-Bold";
  src: url(/static/media/Segoe-UI-Bold.65099f98.ttf);
}

@font-face {
  font-family: "YaHei";
  src: url(/static/media/msyh.4f6a5917.ttc);
}

@font-face {
  font-family: "YaHei-Bold";
  src: url(/static/media/msyhbd.c8d4adc0.ttc);
}

@font-face {
  font-family: "Alibaba-PuHuiTi";
  src: url(/static/media/Alibaba-PuHuiTi-Regular.def5ae30.ttf);
}

/* Smooth scroll */
html {
  scroll-behavior: smooth;
}

/* Global */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font: normal 16px "Poppins-Regular", sans-serif;
  line-height: 1.5;
  color: #000;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

footer {
  font-family: "SegoeUi";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

h1 {
  font-size: 3.5rem;
}

h2 {
  font-size: 2.625rem;
  padding: 4.5rem 0;
}

h3 {
  font-size: 1.375rem;
}

h4 {
  font-size: 14px;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

p {
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.responsive-img {
  max-width: 100%;
  height: auto;
}

.container_2560 {
  position:relative;
  max-width: 2560px;
  margin: 0 auto;
}
.container {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 2rem;
}

.grid-container-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 100%;
  grid-column-gap: 2rem;
}

.grid-container-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  height: 100%;
  grid-column-gap: 2rem;
}

.grid-container-6 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: 100%;
  grid-column-gap: 2rem;
}

.grid-container-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
}

/* Navbar */

.LogoMUxDG {
  max-width:252px;
  width:100%;
}

.navbar {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* fixed height */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 5;
  transition: 0.1s linear all;
  padding:32px 0;
}

.input1 {
  color: red;
}

.navbar .container {
  display: flex;
}

.navbar-brand {
  margin-right: auto;
  display: flex;
  align-items: center;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.navbar-item {
  padding-left: 2vw;
  font-size: 14px;
  
}
.navbar-item:last-child {
  color:#999;
}
.navbar-item:last-child a {
  color:#999;
}

.navbar-link {
  color: #000;
}

.navbar.navbar-light .navbar-link {
  color: #05060f;
}
/* .navbar-link1{
  color: #fff;
} */

.navbar-link:hover {
  color: #e43b27;
}

/* Home */

.home {
  font-family: "Georgia", serif;
  color: #fff;
  /* background: black url("./assets/1.1.png") no-repeat scroll center / cover; */
  /* height: 100vh; */
}

.wrapper {
  width: 68%;
  margin: 180px 0px;
}

.home .containerBG {
  width:100%;
  height: 1734px;
  object-fit: cover;
  display: block;
}
.home h1 {
  margin: 1rem 0 1rem 0;
  font-family: Georgia;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #fff;
}

.home h2 {
  padding: 0;
  font-family: Georgia;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
}

.home p {
  font-family: Georgia;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
}

.home .content_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width:100%;
}
.home .content_wrapper .container {
  height:100%;
}
.home .mu_banner {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home .mu_banner img {
  max-width:346px;
  height:auto;
  margin-bottom:36px;
}

.home .mu_banner p {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: #000;
  margin-bottom:58px;
}

.home .mu_banner a {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  padding: 9px 26px;
  background-color: #e03323;
  min-width:160px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
  display:block;
  height: 48px;
}

.home .mu_banner a:hover,
.home .mu_banner a:focus {
  background-color: #cb2a1b;
}

.home .exploreTradingFuture_banner {
  text-align: left;
  max-width: 470px;
  float: right;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home .exploreTradingFuture_banner h1 {
  font-family: Georgia;
  font-size: 42px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-bottom:22px;
}

.home .exploreTradingFuture_banner p {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #9d9d9d;
  text-shadow: 0px 1px rgba(255, 255, 255, 1);
}

/* Brands */

.brands {
  color: #fff;
  background: black url(/static/media/2.88a1e856.png) no-repeat scroll center / cover;
  position: relative;
  overflow: hidden;
}

.brands h2 {
  position: absolute;
  font-family: "Georgia", sans-serif;
  color: #fff;
  padding: 0;
  margin-top: 4.5rem;
}

.brands .grid-container-4 {
  grid-gap: 0;
}

.brands .grid-container-5 {
  grid-gap: 0;
}

.brand {
  height: 100%;
  position: relative;
  z-index: 2;
  /* border-left: 1px solid rgba(255, 255, 255, 0.16); */
}

.brand:last-child {
  /* border-right: 1px solid rgba(255, 255, 255, 0.16); */
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  transition: all 0.5s ease;
  -webkit-backdrop-filter: blur(60px);
          backdrop-filter: blur(60px);
  background: #ffffff19;
}

.brand-item {
  padding: 242px 10% 24px 10%;
  /* 2rem */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
}

.brand:hover .brand-item {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.brand:hover .overlay {
  opacity: 1;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
}

.brand-item p {
  padding-top: 30px;
  padding-bottom: 24px;
  line-height: 1.75;
  flex: 1 0;
}

.brand-item a {
  display: block;
  color: #fff;
  margin-top: 1rem;
}

.brand-item a:hover {
  color: #e43b27;
}

/* Core Values */

.core-values h2 {
  font-family: "Georgia";
  text-align: center;
}

.core-values h3 {
  color: #333;
  margin-bottom: 2rem;
}

.core-values h4 {
  color: #666;
  margin-bottom: 1rem;
}

.core-values p {
  color: #999;
  font-size: 14px;
}

.outer-grid {
  grid-template-columns: 1.8fr 1.2fr;
}

.inner-grid-left {
  grid-template-columns: auto 1fr;
}

.inner-grid-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.grid-content-top,
.grid-content-bottom {
  grid-template-columns: auto 1fr;
}

.inner-grid-left .grid-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.inner-grid-content {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inner-grid-right .grid-content h3 {
  margin-bottom: 1.25rem;
}

.inner-grid-right .grid-content p {
  font-size: 16px;
  line-height: 1.63;
}

/* Global offices */

.global-offices h2 {
  font-family: "Georgia";
  text-align: center;
  padding-top: 104px;
  padding-bottom: 36px;
}

.global-offices h3 {
  text-align: center;
  padding-bottom: 24px;
}

.global-offices h5 {
  text-align: center;
}

.global-offices .map {
  text-align: center;
  margin-bottom: 7.25rem;
  position: relative;
  overflow: hidden;
  padding-top: 24px;
}

.arrow-down {
  border: solid #666666;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin: 10px 0 35px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  text-align: center;
  position: absolute;
  left: 50%;
}

.hover-text:hover ~ .arrow-down {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
}

.hover-text {
  background: -webkit-linear-gradient(#666666, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
  padding-bottom: 2em;
  transition: 0.5s;
  height: 160px;
}

.hover-text:hover {
  -webkit-text-fill-color: #666666;
  background: none;
  text-overflow: clip;
  white-space: normal;
  opacity: 1;
  width: auto;
  height: 255px;
}

.point {
  position: absolute;
}

.red-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: rgba(230, 28, 28, 1);
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.red-dot:hover {
  -webkit-animation: blink 1s ease-in-out infinite none;
          animation: blink 1s ease-in-out infinite none;
}

.red-dot-blink {
  -webkit-animation: blink 1s ease-in-out infinite none;
          animation: blink 1s ease-in-out infinite none;
}

.red-dot:after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  opacity: 0.1;
  background-color: #e03323;
  border-radius: 50%;
  position: relative;
  top: -4.5px;
  left: -4.5px;
  cursor: pointer;
}

@-webkit-keyframes blink {
  0% {
    box-shadow: 0 0 0 0 rgba(230, 28, 28, 1);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(230, 20, 20, 0.1);
  }
}

@keyframes blink {
  0% {
    box-shadow: 0 0 0 0 rgba(230, 28, 28, 1);
  }
  100% {
    box-shadow: 0 0 0 4px rgba(230, 20, 20, 0.1);
  }
}

.place:hover .tooltip,
.red-dot:hover .tooltip {
  cursor: pointer;
  opacity: 1;
  width: 275px;
  white-space: break-spaces;
}

.place,
.tooltip {
  position: absolute;
  white-space: nowrap;
  top: 0;
  left: 0;
  -webkit-transform: translate(-110%, -35%);
          transform: translate(-110%, -35%);
  pointer-events: none;
}

.tooltip {
  -webkit-transform: translate(-50%, -115%);
          transform: translate(-50%, -115%);
  text-align: left;
  background-color: #fff;
  padding: 12px;
  color: #a3a3a3;
  border-radius: 2px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  opacity: 0;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 1;
  width: 275px;
  white-space: break-spaces;
}

.tooltip:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  left: 48.5%;
  top: 105%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.tooltip-wrap {
  overflow-wrap: break-word;
}

/* Footer */

footer {
  background-color: #000;
  color: #fff;
  padding: 42px 0 16px 0;
}

/* 1 */

footer .left {
  display: flex;
  padding-bottom: 1rem;
  border-bottom: 1px solid #404040;
}

footer .brand-row {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-bottom: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  margin-top: 1.5rem;
}

.about-us {
  margin-right: auto;
  max-width: 20%;
}

.about-us p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin: 1rem 0 1.15rem 0;
}

.social-media p,
.contact-us p {
  font-size: 16px;
  margin-bottom: 14px;
}

.social-media ul,
.contact-us ul {
  font-size: 14px;
  line-height: 1.57;
  color: rgba(255, 255, 255, 0.6);
}
.contact-us .contact-us-row {
  display: grid;
  grid-template-columns: 36px 1fr;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  align-items: center;
  justify-content: center;
}

.contact-us .contact-us-row > p {
  font-size: 14px;
  line-height: 1.57;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 0;
}

.social-media li {
  display: flex;
  align-items: center;
}

.icon {
  margin-top: 2px;
  margin-right: 4px;
}

.social-media li,
.contact-us li {
  margin-bottom: 10px;
}

.social-media a {
  color: rgba(255, 255, 255, 0.6);
}

/* 2 */

footer .logo {
  padding: 20px 0;
  text-align: center;
}

/* 3 */

.name,
.detail,
.website {
  font-size: 12px;
  font-weight: 300;
}

.name-header {
  font-size: 14px;
}

.detail {
  color: rgba(255, 255, 255, 0.45);
  margin-top: 14px;
}

.detail-highlight {
  color: rgba(255, 255, 255, 0.65);
}

.website {
  margin-top: 18px;
}

.website a {
  color: #eb4d33;
}

.wei-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
}

.wei-mask img {
  width: 80%;
  margin-top: 30px;
}

.isDesktop {
  display: block !important;
}

.isMobile {
  display: none !important;
}

@media screen and (max-width: 600px) {
  .isMobile {
    display: block !important;
  }
  .isDesktop {
    display: none !important;
  }
  .unset-margin {
    margin: unset !important;
  }
}

@media only screen and (max-width: 780px) {
  /* Home */
  .home h1 {
    font-size: 2.625rem;
  }
  .home h2 {
    font-size: 16px;
  }
  .home p {
    font-size: 20px;
  }
  .wrapper {
    width: 100%;
  }
  /* Core Values */
  .outer-grid {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
  .inner-grid-left {
    grid-template-columns: 3fr 4fr;
  }
  .inner-grid-left figure,
  .inner-grid-left img {
    max-width: 100%;
    height: auto;
  }
  .grid-content-top,
  .grid-content-bottom {
    grid-template-columns: auto 1fr;
  }
  /* Our brands */
  .brand-item p,
  a {
    font-size: 12px;
  }
  /* Footer */
  footer .logos {
    grid-template-columns: repeat(2, 1fr);
  }

  footer .grid-container-6,
  footer .left {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  footer .grid-container-2 {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 32px;
  }
  footer .about-us {
    max-width: unset;
  }
  footer .brand-row {
    margin-top: 3rem;
    grid-column-gap: 4rem;
    -webkit-column-gap: 4rem;
            column-gap: 4rem;
  }
  .website {
    margin-top: 8px;
  }
}

footer .grid-container-2 {
  grid-column-gap: 28px;
  grid-row-gap: 40px;
  padding-top: 32px;
}

/* pageError */
.pageError .pageError-wrapper {
  margin-top: ;
  display: flex;
  justify-content: center;
  margin: 265px auto 450px;
}
.pageError .pageError-image-wrapper {
}
.pageError .pageError-image {
  max-width: 188px;
  height: auto;
}
.pageError .pageError-content {
  margin-left: 40px;
}
.pageError .pageError-text {
  font-size: 22px;
  margin-bottom: 40px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #000018;
  padding: 0;
}
.pageError .pageError-link {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #d91d22;
  cursor: pointer;
}

.Collapsible__trigger {
  font-size: 16px;
}

.footer .name {
  margin-top: unset;
}

