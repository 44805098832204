html[lang="en"] .image-row-content .title,
html[lang="en"] .image-row-content .description,
html[lang="en"] .blog-content .title,
html[lang="en"] .blog-content .description,
html[lang="en"] .blog-content .date,
html[lang="en"] .three-image-banner-content .description,
html[lang="en"] .image-with-text-box-container-mobile > div > .red-content > .description,
html[lang="en"] .mobile-nav-content > .mobile-language-row,
html[lang="en"] .language-option,
html[lang="en"] .image-with-text-box-description {
  font-family: SegoeUI;
}
html[lang="tc"].image-row-content .description,
html[lang="tc"] .blog-content .title,
html[lang="tc"] .blog-content .description,
html[lang="tc"] .blog-content .date,
html[lang="tc"] .three-image-banner-content .description,
html[lang="tc"] .image-with-text-box-container-mobile > div > .red-content > .title,
html[lang="tc"] .image-with-text-box-container-mobile > div > .red-content > .description,
html[lang="tc"] .mobile-menu-panel .title,
html[lang="tc"] .mobile-nav-content > div > a,
html[lang="tc"] .mobile-nav-content > div > .language-text,
html[lang="tc"] .mobile-nav-content > .mobile-language-row,
html[lang="tc"] .language-option,
html[lang="tc"] .image-with-text-box-description {
  font-family: YaHei;
}

html[lang="en"] .mobile-nav-content > div > a,
html[lang="en"] .mobile-nav-content > div > .language-text,
html[lang="en"] .mobile-menu-panel .title {
  font-family: Oswald;
}

html[lang="en"] .three-image-banner-content .title,
html[lang="en"] .image-with-text-box-container-mobile > div > .red-content > .title,
html[lang="en"] .image-with-text-box-title {
  font-family: Oswald-Bold;
}

html[lang="en"] .landing-banner-button {
  font-family: SegoeUI-Bold;
}

html[lang="tc"] .three-image-banner-content .title,
html[lang="tc"] .landing-banner-button,
html[lang="tc"] .image-with-text-box-title,
html[lang="tc"] .image-row-content .title {
  font-family: YaHei-Bold;
}

.sticky-header {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  height: 152px;
}

.scrollMargin {
  scroll-margin-top: 152px;
}

.padding-sticky-header {
  height: 152px;
}

.nav-bar {
  background-color: #000;
  width: 100%;
  height: 36px;
  /* fixed height */
}

.nav-bar > div:first-child {
  max-width: 1404px;
  color: white;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}

.language-text {
  margin-left: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.globe {
  height: 16px;
  width: 16px;
  margin-right: 0.75rem;
}

.header-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 116px;
  padding: 24px 0 24px 0;
  margin: 0 auto;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
  box-shadow: 0 4px 32px 0 rgba(30, 30, 30, 0.15);
  background-color: #fff;
  align-items: center;
  justify-content: center;
}

.mobile-header-box {
  display: none;
  flex-direction: row;
  width: 100%;
  height: 76px;
  padding: 16px 24px;
  box-shadow: 0 4px 32px 0 rgba(30, 30, 30, 0.15);
  background-color: #fff;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
}

.hamburger {
  width: 24px;
  height: 24px;
}

.haeder-logo {
  width: 255px;
  margin: 0 36px;
}

html[lang="en"] .header-link {
  font-family: Oswald;
}
html[lang="tc"] .header-link {
  font-family: YaHei;
}

.header-link {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  padding: 0 36px;
}

.menu-selected > .header-link {
  color: #e03323 !important;
}

.menu-selected .underline {
  display: block !important;
}

.menu-selected {
  color: #e03323 !important;
}

.header-wrapper > .underline {
  display: none;
  border-bottom: 2px solid #e03323;
  width: 24px;
  margin: 0 auto;
}

.header-link:hover {
  color: #e03323;
}

.landing-container {
  max-width: 1920px;
  margin: 0 auto;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #000;
  min-width: 100px;
  padding: 12px 16px;
  z-index: 1;
  flex-direction: column;
}

.dropdown-content > a {
  padding: 8px 0;
  color: white;
}

.dropdown-content > a:hover {
  color: #e03323;
}

.dropdown:hover .dropdown-content {
  display: flex;
}

.language-text:hover {
  color: #e03323;
}

.image-with-text-box-container {
  max-width: 1404px;
  position: relative;
  margin: 120px auto;
}

.image-with-text-box-container > img {
  max-width: 100%;
  width: 929px;
  height: 525px;
}

.image-with-text-box-container > .content {
  position: absolute;
  top: 15%;
  right: 0;
  background-color: #e03323;
  width: 688px;
  padding-left: 72px;
  padding-right: 100px;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  flex-direction: row;
}

.image-with-text-box-container > .content > img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-top: 11px;
}

.image-with-text-box-description {
  padding-top: 16px;
  font-size: 16px;
  text-align: left;
  color: #fff;
}

.image-with-text-box-title {
  font-family: Oswald;
  text-transform: uppercase;
  font-size: 40px;
  line-height: 1.3;
  text-align: left;
  color: #fff;
}

.landing-banner {
  height: 950px;
  /* height: calc(100vh - 116px - 36px);
  min-height: 950px;
  max-height: 950px; */
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  background-image: url("../../assets/MuLanding/Image-header.png");
}

.landing-title {
  padding-top: 40px;
  flex-direction: column;
  display: flex;
  padding-bottom: 0px;
}

.landing-title-1 {
  text-transform: uppercase;
  font-family: Oswald;
  font-size: 120px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #e03323;
}

.center-title-1 {
  text-transform: uppercase;
  padding-top: 8px;
  font-family: Oswald;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #3e3a39;
}
.center-title-2 {
  text-transform: uppercase;
  font-family: Oswald;
  font-size: 120px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #e03323;
}

.landing-title-cn-1 {
  text-transform: uppercase;
  font-family: YaHei;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #3e3a39;
}
.landing-title-2 {
  text-transform: uppercase;
  padding-top: 8px;
  font-family: Oswald;
  font-size: 56px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #3e3a39;
}

.landing-title-cn-2 {
  padding-top: 8px;
  text-transform: uppercase;
  font-family: YaHei-Bold;
  font-size: 120px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #e03323;
}

html[lang="en"] .landing-title-3 {
  font-family: SegoeUI;
}
html[lang="tc"] .landing-title-3 {
  font-family: YaHei;
}
.landing-title-3 {
  padding-top: 24px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #3e3a39;
}

.landing-banner-button {
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: 232px;
  border-bottom: 1px solid #e03323;
  color: #e03323;
  padding: 16px 36px;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
}
.landing-banner-button:hover {
  color: #fff;
  background-color: #e03323;
}

.foot-image {
  display: flex;
  justify-content: baseline;
  /* max-width: 1920px; */
  width: 100%;
  margin: 0 auto;
}

.foot-image > .desktop,
.mobile {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
}

.foot-image > .desktop {
  display: block;
  height: 200px;
}

.foot-image > .mobile {
  min-height: 385px;
  display: none;
}

.center-banner {
  background-image: url("../../assets/MuLanding/Image-Future.png");
  min-height: 1280px;
}

html[lang="en"] .center-banner-description {
  font-family: SegoeUI;
}
html[lang="tc"] .center-banner-description {
  font-family: YaHei;
  max-width: 514px;
}

.center-banner-description {
  max-width: 690px;
  margin: 24px auto;
  font-family: SegoeUI;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #3e3a39;
}

.center-banner-title {
  flex-direction: column;
  display: flex;
  padding-bottom: 0px;
  padding-top: 120px;
}

.center-title {
  text-transform: uppercase;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

html[lang="en"] .center-title > h2 {
  font-family: Oswald;
}
html[lang="tc"] .center-title > h2 {
  font-family: YaHei;
}

.center-title > h2 {
  font-size: 56px;
  line-height: 1.29;
  text-align: center;
  color: #3e3a39;
  padding-bottom: 24px;
  padding-top: 120px;
}

html[lang="en"] .center-title > p {
  font-family: SegoeUI;
}
html[lang="tc"] .center-title > p {
  font-family: YaHei;
}

.center-title > p {
  text-transform: none;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #3e3a39;
}

.arrow-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-top: 11px;
}

.three-image-row {
  max-width: 1404px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin: 0 auto;
  margin-top: 80px;
  justify-content: center;
}

.image-row-content {
  display: flex;
  flex-direction: column;
}

.image-row-content > img {
  padding-left: 24px;
}

.image-row-content > div {
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  padding-right: 24px;
}

.small-arrow-icon {
  width: 20px;
  height: 20px;
  margin-right: 16px;
  margin-top: 6px;
}

.fade-arrow-icon {
  opacity: 50%;
  width: 20px;
  height: 20px;
  margin-right: 4px;
  margin-top: 6px;
}

.image-row-content .title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
}
.image-row-content .description {
  padding-top: 16px;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
}

.image-row-content .first-image-content {
  padding-left: 24px;
}

.grey-bg {
  background-color: #fafafa;
  padding-bottom: 120px;
}

.three-blog-row {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  margin-bottom: 160px;
  justify-content: center;
  position: relative;
}

.three-blog-row > div {
  display: flex;
  overflow-y: auto;
  flex-direction: row;
}

.three-blog-row > .next-button,
.three-blog-row > .previous-button {
  display: block;
  border: 1px solid #e03323;
  padding: 6.5px 8px;
  z-index: 10;
  position: absolute;
  bottom: -22px;
  background: white;
  cursor: pointer;
}

.three-blog-row > .next-button:disabled,
.three-blog-row > .previous-button:disabled {
  border-color: #d4d4d4;
  background: white;
  cursor: not-allowed;
}

.three-blog-row > .next-button:disabled > svg,
.three-blog-row > .previous-button:disabled > svg {
  color: #d4d4d4;
}

.three-blog-row > .next-button > svg,
.three-blog-row > .previous-button > svg {
  height: 30px;
  color: #e03323;
}

.three-blog-row > .next-button {
  left: calc(164px + 249px);
}

.three-blog-row > .next-button > svg {
  transform: rotate(180deg);
}

.three-blog-row > .previous-button {
  left: calc(100px + 249px);
}

.three-blog-row-margin-bottom > div > a {
  margin-bottom: 80px;
}

/* width */
.three-blog-row > div::-webkit-scrollbar {
  width: 20px;
  height: 5px;
}

/* Track */
.three-blog-row > div::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  background: #e3e3e3;
  margin-left: calc(15vw + 249px);
  margin-right: 25vw;
  margin-top: 60px;
}

/* Handle */
.three-blog-row > div::-webkit-scrollbar-thumb {
  background: #e03323;
}

.three-blog-row > div > div {
  height: 100%;
  padding: 0 12px;
}

.blog-content {
  max-width: 452px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  padding-top: 16px;
  transition: padding 0.5s;
  transition-timing-function: ease-in-out;
}
.blog-content:hover {
  padding-top: 0px;
  padding-bottom: 16px;
}

.blog-content > div {
  height: 100%;
  padding: 32px 32px;
  color: #000;
  background-color: #fafafa;
}

html[lang="en"] .blog-content .title {
  text-transform: capitalize;
  min-height: 96px;
}

.blog-content .title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  text-align: left;
  color: #000;
}

.blog-content .description {
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  padding-top: 16px;
}

.blog-content .date {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  justify-self: end;
  color: #9e9e9f;
  padding-top: 24px;
}
.three-image-banner-main-container {
  padding-top: 75px;
}

.three-image-banner-main-container > div {
  display: flex;
  flex-direction: column;
}

.three-image-banner-main-container > div {
  margin: 0 auto;
}

.three-image-banner-main-container > div > div {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  margin: 0 auto;
  max-width: 1646px;
}

.three-image-banner-content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  z-index: 2;
}

.three-image-banner-content > div {
  display: flex;
  flex-direction: row;
  margin: auto auto;
  padding-top: 120px;
}

.three-image-banner-content .title {
  text-transform: uppercase;
  font-size: 40px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 16px;
}

.three-image-banner-content .description {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 120px;
}

.image-with-text-box-container-mobile {
  display: none;
  padding-top: 80px;
}
.image-with-text-box-container-mobile > div {
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  position: relative;
  height: auto;
}

.image-with-text-box-container-mobile > div > img {
  width: 100%;
  object-fit: contain;
}

.image-with-text-box-container-mobile > div > .red-content {
  display: flex;
  position: absolute;
  top: 90%;
  flex-direction: column;
  background-color: #e03323;
  color: #fff;
  padding: 32px 24px;
  margin: 0 8px;
}

.image-with-text-box-container-mobile > div > .red-content > .title {
  text-transform: uppercase;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
}

.image-with-text-box-container-mobile > div > .red-content > .description {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  padding-top: 16px;
}

.three-image-banner-image-content {
  display: flex;
}

.three-image-banner-image-content > img {
  width: 100%;
  object-fit: cover;
  justify-content: baseline;
}

.three-image-banner-image-content-float {
  display: flex;
  position: relative;
  width: 100%;
}

.three-image-banner-image-content-float > img {
  position: absolute;
  bottom: 0;
  width: 100%;
  object-fit: contain;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-6 {
  grid-column: span 6 / span 6;
}
.col-span-7 {
  grid-column: span 7 / span 7;
}
.red-bg {
  background-color: #e03323;
  color: #fff;
}

.center-banner-image {
  display: none;
}

.mobile-menu-panel {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  left: -100vw;
  animation-timing-function: ease-in-out;
  animation-duration: 0.5s;
}

.mobile-menu-panel-go-in {
  animation-name: go-in;
  left: 0;
}

@keyframes go-in {
  from {
    left: -100vw;
  }
  to {
    left: 0;
  }
}

.mobile-menu-panel-go-out {
  animation-name: go-out;
  left: -100vw;
}

@keyframes go-out {
  from {
    left: 0;
  }
  to {
    left: -100vw;
  }
}

.mobile-menu-panel > div {
  display: flex;
  flex-direction: row;
}

.mobile-menu-panel .title {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
}

.player-wrapper {
  position: relative;
  max-height: min(calc(100vh - 150px), 1280px) !important;
  /* padding-top: 56.25%; */
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  /* top: 0;
  left: 0; */
}

.overlay-prevent-click {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 40px);
  z-index: 1;
}

.play-button-video {
  margin: auto;
  margin: auto;
  height: 200px;
  width: 200px;
  cursor: pointer;
}

.play-button {
  margin-top: auto;
  margin-bottom: 232px;
  height: 200px;
  width: 200px;
  cursor: pointer;
}

.first-blog {
  margin-left: 249px;
}

@media screen and (max-width: 1400px) {
  .first-blog {
    margin-left: 0px;
  }

  .three-blog-row > .next-button {
    left: 264px;
  }

  .three-blog-row > .previous-button {
    left: 200px;
  }

  .three-image-banner-image-content-float > img {
    bottom: 0;
    width: 100%;
    object-fit: contain;
  }
  .three-image-banner-image-content {
    position: relative;
  }
  .three-image-banner-image-content > img {
    bottom: 0;
    position: absolute;
    object-fit: contain;
  }
  .three-blog-row > div::-webkit-scrollbar-track {
    margin-left: 25vw;
  }
}
@media screen and (max-width: 1400px) {
  .three-blog-row > .next-button {
    left: 164px;
  }

  .three-blog-row > .previous-button {
    left: 100px;
  }
}

@media screen and (max-width: 600px) {
  .three-image-banner-image-content > img,
  .three-image-banner-image-content-float > img {
    position: unset;
  }
  .isMobile {
    display: block !important;
  }
  .isDesktop {
    display: none !important;
  }
  .scrollMargin {
    scroll-margin-top: 80px;
  }

  .app {
    overflow-x: hidden;
  }
  .header-box,
  .nav-bar {
    display: none !important;
  }

  .sticky-header,
  .padding-sticky-header {
    height: 76px;
  }
  .mobile-header-box {
    display: flex !important;
  }

  .foot-image > .desktop {
    display: none;
  }

  .foot-image > .mobile {
    display: block;
  }

  .landing-title-1,
  .landing-title-cn-2,
  .center-title-2 {
    font-size: 49px;
  }

  .landing-title-2,
  .landing-title-cn-1,
  .center-title-1 {
    font-size: 28px;
  }
  .landing-title-3 {
    padding: 16px 25px;
    font-size: 16px;
  }

  .landing-banner {
    height: 564px;
    min-height: unset;
    max-height: unset;
    background-size: 280%;
    background-image: url("../../assets/MuLanding/mobile/Image-Header.png");
  }
  .landing-banner-button {
    padding: 12px 16px;
    margin-bottom: 122px;
  }

  .play-button {
    margin-bottom: 100px;
  }

  .center-title {
    padding: 0 24px;
  }
  .center-title > h2 {
    font-size: 28px;
    padding-bottom: 18px;
    padding-top: 80px;
  }

  .center-title > p {
    font-size: 16px;
  }

  .image-with-text-box-container {
    display: none;
  }
  .image-with-text-box-container-mobile {
    display: block;
  }

  .image-row-content {
    width: 90vw;
  }

  .blog-content {
    width: 80vw;
  }

  .blog-content {
    padding-bottom: 60px;
  }

  .blog-content .title {
    min-height: unset !important;
    font-size: 20px;
  }

  .blog-content .description {
    font-size: 16px;
  }

  .image-row-content .description {
    padding-bottom: 100px;
  }

  .image-row-content > img {
    padding-left: 8px;
  }

  .image-row-content > div {
    padding-right: 24px;
    padding-left: 8px;
  }

  .image-row-content .first-image-content {
    padding-left: 8px;
  }
  .three-blog-row,
  .three-image-row {
    overflow-y: auto;
    grid-template-columns: repeat(3, minmax(min-content, max-content));
    justify-content: start;
    padding: 0 8px;
    margin-top: 40px;
  }
  .three-image-row .fade-arrow-icon {
    display: none;
  }
  .three-blog-row {
    margin-bottom: 80px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .three-blog-row > div {
    padding-left: 12px;
    padding-right: 12px;
  }

  .three-blog-row-margin-bottom > div > a {
    margin-bottom: unset;
  }

  .three-blog-row > .next-button,
  .three-blog-row > .previous-button {
    display: none !important;
  }

  /* Track */
  .three-blog-row > div::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 60px;
  }
  /* width */
  .three-image-row::-webkit-scrollbar {
    width: 20px;
    height: 5px;
    padding-top: 60px;
  }

  /* Track */
  .three-image-row::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    background: #e3e3e3;
    margin: 24px;
    margin-top: 60px;
  }

  /* Handle */
  .three-image-row::-webkit-scrollbar-thumb {
    background: #e03323;
  }

  .center-banner {
    background-image: none;
    height: auto;
    padding-top: 80px;
    position: relative;
  }

  .center-banner > .center-banner-title,
  .center-banner > .center-banner-description {
    padding: 0 24px;
    margin-bottom: 0;
    z-index: 1;
  }
  html[lang="en"] .center-banner > .center-banner-description {
    padding-bottom: 350px;
  }
  html[lang="tc"] .center-banner > .center-banner-description {
    padding-bottom: 340px;
  }

  .center-banner-title {
    padding-top: 80px;
  }

  .center-banner-image {
    height: 483px;
    display: block;
    position: absolute;
    justify-self: baseline;
    justify-items: baseline;
    object-fit: contain;
    z-index: -1;
  }

  html[lang="en"] .center-banner > .center-banner-image {
    top: 30%;
  }

  html[lang="tc"] .center-banner > .center-banner-image {
    top: 20%;
  }

  .three-image-banner-main-container {
    padding-top: 0px;
  }

  .three-image-banner-main-container > div > div {
    display: flex;
    flex-direction: column;
    /* grid-template-columns: repeat(12, minmax(0, 1fr));
    margin: 0 auto;
    max-width: 1646px; */
  }

  .three-image-banner-content > div {
    display: flex;
    flex-direction: row;
    margin: auto auto;
    padding-top: 80px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .three-image-banner-content .title {
    font-size: 24px;
    padding-bottom: 16px;
  }

  .three-image-banner-content .description {
    font-size: 16px;
    padding-bottom: 40px;
  }

  .grey-bg {
    padding-bottom: 80px;
  }
  .three-blog-row > div {
    column-gap: 8px;
    padding-bottom: 60px;
  }
  .blog-content {
    padding: 0px;
  }
  .small-arrow-icon {
    width: 16px;
    height: 16px;
  }
  .arrow-icon {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    margin-top: 8px;
  }
}

.mobile-nav-bar {
  position: fixed;
  background: #fff;
  width: 100%;
  height: 80px;
}

.mobile-nav-bar > div {
  float: right;
  margin-right: 2rem;
  margin-top: 80px;
}

.mobile-nav-bar > div {
  position: fixed;
  background: #fff;
  width: 100%;
  height: calc(100vh - 80px);
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  transition: all 0.5s;
  left: -100%;
  padding: 80px 24px;
}

.mobile-nav-bar #menuBtn:checked ~ div {
  left: 0;
}

.mobile-nav-content > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 40px;
  width: 100%;
}

.mobile-nav-content > div > a,
.mobile-nav-content > div > .language-text {
  text-transform: uppercase;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #3e3a39;
}

.mobile-nav-content > div > img {
  height: 16px;
  width: 16px;
  margin-right: 16px;
}

.mobile-nav-content > hr {
  color: #e3e3e3;
  height: 1px;
  width: 100%;
}

.mobile-nav-content > .mobile-language-row {
  padding-top: 80px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 0.88;
  text-align: left;
  color: #3e3a39;
}

.mobile-nav-content > .mobile-language-row img {
  height: 16px;
  width: 16px;
  margin: auto 0;
}
.mobile-nav-content > .mobile-language-row p {
  margin-right: 8px;
  margin-left: 8px;
}

.language-option {
  padding-left: 40px;
  font-size: 16px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

/* caption styling */
.mu-video::cue {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}

html[lang="en"] .mu-video::cue {
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 32px;
}

html[lang="tc"] .mu-video::cue {
  font-family: Alibaba-PuHuiTi;
  font-weight: 500;
  /* font-size: 38px; */
}

@media screen and (max-width: 600px) {
  html[lang="tc"] .mu-video::cue,
  html[lang="en"] .mu-video::cue {
    font-size: 16px;
  }
}

.vjs-text-track-display div {
  font-size: 1.2em;
  color: #fff;
}

html[lang="en"] .vjs-text-track-display div{
  font-family: Poppins-Regular;
  font-weight: 600;
  font-size: 32px;
}

html[lang="tc"] .vjs-text-track-display div{
  font-family: Alibaba-PuHuiTi;
  font-weight: 500;
  /* font-size: 38px; */
}

@media screen and (max-width: 600px) {
  html[lang="tc"] .vjs-text-track-display div,
  html[lang="en"] .vjs-text-track-display div{
    font-size: 16px;
  }
}