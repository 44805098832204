.react-tabs__tab {
  font: "SegoeUI";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: relative;
  margin-top: 24px;
  margin-right: 80px;
  margin-top: 29px;
  margin-bottom: 24px;
  padding-left: 0px;
  padding-right: 0px;
}

.style1 {
  font-family: "SegoeUI";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.45);
}

.tester {
  width: 100%;
  background-color: #000;
}

.border_bottom_line {
  border-bottom: solid 1px #404040;
  padding-bottom: 20px;
}

.margin_table {
  margin-bottom: 5px;
}

.react-tabs__tab-panel--selected {
  display: inline-block;
  padding: 24px 0;
}

.table_border1 {
  border-top: 1px solid #404040;
  padding-top: 1.5rem;
}

.react-tabs__tab--selected {
  background: #000;
  border-color: #000;
  color: white;
}

.react-tabs {
  border-bottom: 1px solid #404040;
  border-top: 1px solid #404040;
  margin: 0 0 10px;
  padding: 0;
  margin-bottom: 24px;
}

.Collapsible__contentOuter {
  border-top: 1px solid #404040;
  margin: 0 0 10px;
  padding: 0;
}

/* .zh_lang {
color:red
} */

.tc_lang {
  color: green;
}

.en_lang {
  color: #ffffff;
}

.blue {
  color: blue;
}
.p_jurisdiction {
  color: #d91d22;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #404040;
  margin: 0;
}

.entity_style {
  font-size: 12px;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
}

.collapsible-trigger {
  display: flex;
  flex-direction: row;
  padding-bottom: 24px;
}
@media screen and (max-width: 600px) {
  .copyright {
    margin-top: unset !important;
  }
  .table_border1 {
    padding-top: 16px;
  }
  .collapsible-trigger {
    padding-bottom: 16px;
    justify-content: space-between;
  }
  .collapsible-trigger > p {
    font-size: 14px;
  }

  .Collapsible__contentOuter {
    border-top: unset;
    border-bottom: 1px solid #404040;
  }
  .border_bottom_line {
    border-bottom: unset;
  }
}

.collapsible-trigger-img {
  height: 24px;
  width: 24px;
  transform: rotate(-90deg);
  margin: auto 0;
}

.is-open .collapsible-trigger-img {
  transform: rotate(-180deg);
}
/* .Collapsible__trigger is-open{
    font-size: 12px;
    padding-top:24px;
    position:relative;

} */

.Collapsible__trigger {
  position: relative;
}

.react-tabs__tab::after {
  top: 54px;
}

.react-tabs__tab--selected::after {
  /* display block for selected */
  display: block;
}
.react-tabs__tab--selected::after {
  transform: rotate(180deg);
}

.complianceWrapper h5:after {
  content: "";
  max-height: 516px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 9px;
  width: 10px;
  float: right;
  position: absolute;
  right: 67%;
  top: 50%;
  margin-top: -7.28px;
}

.react-tabs__tab--selected {
  position: relative;
}

.react-tabs__tab--selected::after {
  content: "";
  width: 50px;
  height: 1px;
  position: absolute;
  left: 50%;
  bottom: 1;
  margin-left: -25px;
  background-color: #ff0000;
  z-index: 1;
}

.react-tabs__tab {
  position: relative;
  margin-top: 16px;
}

.copyright {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #404040;
  width: 100%;
  background: #000;
  color: white;
  font-family: SegoeUI;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
}

.Collapsible__contentInner {
  padding: 0 0;
}

.career {
  display: grid;
  row-gap: 1rem;
  font-family: SegoeUI;
}

.career > a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}
.career > a:hover {
  color: #fff;
}

footer .detail .website {
  margin-top: 16px;
}

.border-bottom-un-set {
  border-bottom: unset;
}
